<template>
	<div class="row reg-content q-pa-md q-col-gutter-x-lg bg-grey-3 items-center">
		<div class="col-md-6">
			<div :class="$q.screen.lt.sm ? '' : 'q-py-lg q-px-xl'">
				<p>
					By registering to receive messages from the {{ service_name }}, I understand that:
				</p>
				<ul class="margin-bottom bullet-points-color">
					<li>
						In the unlikely event of an incident, I may receive emergency information in the following ways:
						<ul>
							<li>Voice message to my landline</li>
							<li>Voice message to my mobile</li>
							<li>Text message to my mobile</li>
							<li>Email to my email address</li>
						</ul>
					</li>
					<li>
						{{ customer_name }} and Alert Cascade Limited cannot be held accountable for any failure in the telephone,
						cellular or email networks.
					</li>
					<li>
						If I do not receive any of the messages from the {{ service_name }}, neither {{ customer_name }} nor Alert
						Cascade can be held accountable or liable.
					</li>
					<li>
						Only messages authorised by {{ customer_name }} will be sent from the {{ service_name }}.
					</li>
					<li>
						The {{ service_name }} is a business continuity messaging system for incidents that may affect staff and
						additional workers at {{ customer_name }} premises, and if I need to contact the emergency services, I must
						dial 999 as usual, or contact the {{ customer_name }} security control room on extension 3333 if I am onsite
						at {{ address_1 }}.
					</li>
					<li>
						Messages from the {{ service_name }} may contain emergency instructions and it is important that I listen
						to the whole message.
					</li>
					<li>
						This service is free of charge.
					</li>
					<li>
						These terms and conditions may change. If they do change, I will be told about the changes and I can chose to
						de-register my details at any time.
					</li>
				</ul>
			</div>
		</div>
		<div class="col-md-6 justify-center" style="max-width: 100%;">
			<div :class="$q.screen.lt.sm ? '' : 'q-py-lg q-px-xl'">
				<register-form/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RegisterForm from '@/components/registration_template/RegisterForm.vue';

export default {
	name: 'RegisterTemplateSRP',
	components: {
		RegisterForm
	},
	computed: {
		...mapGetters([
			'customer_name',
			'service_name',
			'address_1'
		])
	},
}
</script>

<style scoped>

	.reg-content {
		font-size: 16px;
		cursor: default;
	}

	.reg-content li:before {
		content: "■";
		font-size: 18px;
		margin-right: 5px;
	}

	.reg-content ul li {
		padding-bottom: 10px;
		list-style: none;
		text-indent: -.7em;
	}

	@media(max-width: 800px) {
		.reg-content {
			font-size: 13px;
		}
		.reg-content ul {
			padding: 1rem;
		}
	}
</style>
