<template>
	<q-footer elevated class="bg-grey-8 text-white">
		<div class="row register-template-footer justify-center">
			<div class="col-12 col-md-3">
				<div :class="{
					'q-py-md': $q.screen.gt.sm,
					'q-px-xl': $q.screen.gt.sm,
					'text-center': true
				}">
					<h3 class="size-16-bold uppercase">ALERT CASCADE LTD</h3>
					<ul>
						<li><i class="fas fa-map-marker-alt"></i><span> Allia Future Business Centre, London Road, Peterborough</span></li>
						<li>PE2 8AN</li>
						<li><i class="fa fa-phone"></i><span> 01733 785999</span></li>
						<li>
							<i class="fa fa-envelope"></i>
							<span>
								<a class="footer-link pointer" href="mailto:support@alertcascade.co.uk">
									info@alertcascade.co.uk
								</a>
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div
				v-if="!registration_template_settings.template.enable_multi_branding || multi_branding_all_names_null"
				class="col-12 col-md-3"
			>
				<div :class="{
					'q-py-md': $q.screen.gt.sm,
					'q-px-xl': $q.screen.gt.sm,
					'text-center': true
				}">
					<h3 class="size-16-bold uppercase">
						{{ registration_template_settings.template.customer_name }}
					</h3>
					<ul>
						<li>
							<i class="fas fa-map-marker-alt"></i>
							<span> {{ registration_template_settings.template.address_1 }}</span>
						</li>
						<li>{{ registration_template_settings.template.address_2 }}</li>
						<li>{{ registration_template_settings.template.address_3 }}</li>
						<li>{{ registration_template_settings.template.address_4 }}</li>
						<li><i class="fa fa-phone"></i><span> {{ registration_template_settings.template.phone_number }}</span></li>
						<li>
							<i class="fa fa-envelope"></i>
							<span>
								<a class="footer-link pointer" :href="`mailto:${registration_template_settings.template.email}`">
									 {{ registration_template_settings.template.email }}
								</a>
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div
				v-if="registration_template_settings.template.enable_multi_branding && !multi_branding_all_names_null"
				class="col-12 col-md-3"
			>
				<div :class="{
					'q-py-md': $q.screen.gt.sm,
					'q-px-xl': $q.screen.gt.sm,
					'text-center': true
				}">
					<h3 class="size-16-bold uppercase">Operators</h3>
					<ul v-for="multi_brand in multi_branding" :key="multi_brand.name">
						<li v-if="multi_brand.name">
							<i class="fa fa-angle-right"></i>
							<a
								v-if="multi_brand.link"
								class="footer-link pointer"
								target="_blank"
								:href="multi_brand.link ? multi_brand.link : '#'"
							>
								{{ multi_brand.name }}
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div :class="{
					'q-py-md': $q.screen.gt.sm,
					'q-px-xl': $q.screen.gt.sm,
					'text-center': true
				}">
					<h3 class="size-16-bold uppercase">Pages</h3>
					<ul>
						<li>
							<i class="fa fa-angle-right"></i>
							<router-link
								:to="{ name: 'landing'}"
								class="footer-link pointer"
								@click.native="redirect"
							>
								Welcome
							</router-link>
						</li>
						<li>
							<i class="fa fa-angle-right"></i>
							<router-link
								:to="{ name: 'register'}"
								class="footer-link pointer"
								@click.native="redirect"
							>
								Register
							</router-link>
						</li>
						<li>
							<i class="fa fa-angle-right"></i>
							<router-link
								:to="{ name: 'deregister'}"
								class="footer-link pointer"
								@click.native="redirect"
							>
								De-register
							</router-link>
						</li>
					</ul>
				</div>
			</div>

			<div class="col-12 col-md-3">
				<div :class="{
					'q-py-md': $q.screen.gt.sm,
					'q-px-xl': $q.screen.gt.sm,
					'text-center': true
				}">
					<h3 class="size-16-bold uppercase">Legal</h3>
					<ul>
						<li>
							<i class="fa fa-angle-right"></i>
							<a class="footer-link pointer" :href="registration_template_settings.template.ico_link" target="_blank">
								Information Commissioner’s Office
							</a>
						</li>
						<li>
							<i class="fa fa-angle-right"></i>
							<a class="footer-link pointer" href="https://alertcascade.co.uk/privacy" target="_blank">
								Privacy
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="register-template-copyright-info row q-pa-md">
			<div class="company-name offset-md-5 col-md-7"> © Alert Cascade Ltd {{ current_year }} |
				<a href="https://alertcascade.co.uk/" style="color: inherit;" target="_blank">www.alertcascade.co.uk</a>
			</div>
		</div>
	</q-footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	name: 'Footer',
	data () {
		return {
			multi_branding_all_names_null: true,
			current_year: ''
		}
	},
	computed: {
		...mapGetters(['registration_template_settings', 'multi_branding']),
	},
	methods: {
		...mapActions(['updateActiveMenuItem']),
		redirect () {
			this.updateActiveMenuItem(this.$route.name)
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	},
	created() {
		this.multi_branding_all_names_null = this.multi_branding.every(function (multibrand) {
			return multibrand['name'] === null;
		});
		this.current_year = new Date().getFullYear();
	}
}
</script>

<style scoped>
	.register-template-footer {
		background: #181818;
	}

	.register-template-footer h3 {
		color: #ffffff;
		padding-bottom: 10px;
		line-height: 1rem;
	}

	.register-template-footer ul {
		list-style-type: none;
		padding-left: 0;
		line-height: 23px;
		color: #ABABAB;
	}

	.register-template-copyright-info {
		color: #ABABAB;
		background: #303030;
		font-size: 12px;
	}

	.footer-link {
		color: #ABABAB;
		text-decoration: none;
	}

	.footer-link:hover {
		color: #ffffff !important;
	}

	.company-name {
		cursor: default;
	}

</style>
