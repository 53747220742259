<template>
	<div>
		<q-header elevated class="bg-black q-pl-xl" v-if="$q.screen.gt.sm">
			<q-toolbar class="bg-dark text-white q-pa-none">
				<div class="col-sm-12 row col-md-6">
					<div class="col-sm-4 flex items-center">
						<img
							alt="Logo image"
							style="max-width:100px;"
							:src="registration_template_settings.template.logo"
						>
					</div>
				</div>
				<q-space />
				<q-btn
					v-for="item in menuList"
					:key="item.value"
					:style='{color: item.value === active_link ? lighten_links_color : active_links_color}'
					flat
					stretch
					:label="item.label"
					@click="redirect(item.value)"
				/>
			</q-toolbar>
		</q-header>
		<div v-else>
			<q-header elevated class="bg-black">
				<q-toolbar>
					<q-btn flat @click="drawerLeft = !drawerLeft" round dense icon="menu" />
					<q-toolbar-title>
						<div class="col-sm-4 flex items-center">
							<img
								alt="Logo image"
								style="max-width:100px;"
								:src="registration_template_settings.template.logo"
							>
						</div>
					</q-toolbar-title>
				</q-toolbar>
			</q-header>
			<q-drawer
				v-model="drawerLeft"
				show-if-above
				:width="200"
				:breakpoint="500"
				bordered
			>
				<q-scroll-area class="fit">
					<q-list>
						<template v-for="(menuItem, index) in menuList">
							<q-item
								:key="index"
								clickable
								:active="menuItem.value == menu_item"
								v-ripple
								class="menu-font-size"
								@click="redirect(menuItem.value)
							">
								<q-item-section avatar>
									<q-icon :name="menuItem.icon" />
								</q-item-section>
								<q-item-section>
									{{ menuItem.label }}
								</q-item-section>
							</q-item>
							<q-separator :key="'sep' + index" v-if="menuItem.separator" />
						</template>
					</q-list>
				</q-scroll-area>
			</q-drawer>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { colors } from 'quasar'

const { lighten } = colors


export default {
	name: 'Header',
	data () {
		return {
			menu_item: 'landing',
			left: false,
			drawerLeft: false,
			lighten_links_color: '',
			menuList: [
				{
					label: 'Welcome',
					separator: true,
					value: 'landing',
				},
				{
					label: 'Register',
					separator: true,
					value: 'register',
				},
				{
					label: 'De-register',
					separator: true,
					value: 'deregister',
				},
			],
			active_links_color: '',
		}
	},
	props: {
		template_type: Number
	},
	computed: {
		...mapGetters([
			'registration_template_settings',
			'links_color',
			'bullet_points_color',
			'menu_item_footer',
			'custom_login_url',
			'register_button',
			'deregister_button'
		]),
		active_link() {
			return this.$route.name
		}
	},
	methods: {
		...mapActions(['getCustomLoginUrl']),
		redirect(item_value){
			this.menu_item = item_value
			let host = window.location.host.split('.').splice(1).join('.')
			if (item_value == 'Login User' && this.custom_login_url){
				window.location.href = 'https://' + host + '/ui/' + this.custom_login_url
			}else if (item_value == 'Login User'){
				window.location.href = 'https://' + host + '/ui/login'
			}else{
				this.$router.push({ name: item_value })
			}
		}
	},
	async created () {
		colors.setBrand('register-button', this.register_button)
		colors.setBrand('deregister-button', this.deregister_button)
		if (this.links_color) {
			colors.setBrand('links-color', this.links_color)
		}
		this.active_links_color = '#FFFFFF'
		this.lighten_links_color = lighten(this.active_links_color, -33)
		if (this.bullet_points_color) {
			colors.setBrand('bullet-points', this.bullet_points_color)
		}
		this.menu_item = this.$route.name
		if (this.template_type == 1){
			this.menuList.push(
				{
					label: 'Login',
					separator: true,
					value: 'Login User',
				}
			)
		}
	},
	watch: {
		menu_item_footer: function(val) {
			if (this.menu_item == val) {
				this.$router.push({ name: val })
			}
			this.menu_item=val
		},
	}
}
</script>

<style scoped>
	.menu-font-size {
		font-size: 15px;
	}

	.link {
		color: var(--q-color-links-color);
	}
</style>
