<template>
	<div class="landing">
		<div :class="{
			'q-pl-xl': $q.screen.gt.sm,
			'q-pr-xl': $q.screen.gt.sm,
			'q-pt-md': $q.screen.gt.sm,
			'q-pb-lg': $q.screen.gt.sm,
			'q-pa-lg': $q.screen.lt.sm
		}">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Sign up for emergency alerts
			</h1>
			<div class="flex justify-center">
				<div>
					{{ customer_name }} encourages people living and working near {{ site_name }} to sign up for our free alerting
					service, {{ service_name }}. Registering your contact information (mobile, landline, and email) means that we
					can send you emergency information and safety instructions in the unlikely event of an emergency at
					{{ site_name }}.
				</div>
				<div class="q-mt-md">
					<register-button/>
				</div>
			</div>
		</div>

		<div v-if="leaflet" :class="{
			'q-pl-xl': $q.screen.gt.sm,
			'q-pr-xl': $q.screen.gt.sm,
			'q-pt-md': $q.screen.gt.sm,
			'q-pb-lg': $q.screen.gt.sm,
			'q-pa-lg': $q.screen.lt.sm
		}">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				What to do in an emergency?
			</h1>
			<div>
				You should already have a copy of our {{ leaflet_name }}, giving you more information about what happens at
				{{ site_name }} and explaining what you need to do in an emergency. If you do not have an up-to-date copy of
				our {{ leaflet_name }} you can
				<a target="_blank" :href="leaflet" class="text-links-color">
					download the latest version here
				</a>.
			</div>
		</div>

		<div v-if="noticeboard_phone_number" :class="{
			'q-pl-xl': $q.screen.gt.sm,
			'q-pr-xl': $q.screen.gt.sm,
			'q-pt-md': $q.screen.gt.sm,
			'q-pb-lg': $q.screen.gt.sm,
			'q-pa-lg': $q.screen.lt.sm
		}">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Resident information line ({{noticeboard_phone_number}})
			</h1>
			<div>
				You can call our resident information line 24/7/365 to hear the latest situation at {{ site_name }}. You
				will usually hear a message stating “There are no current incidents and {{ site_name }} is operating normally”.
				If we are conducting an exercise, or in the unlikely event of an emergency, this line will be updated to
				provide further information.
			</div>
		</div>

		<div :class="{
			'q-pl-xl': $q.screen.gt.sm,
			'q-pr-xl': $q.screen.gt.sm,
			'q-pt-md': $q.screen.gt.sm,
			'q-pb-lg': $q.screen.gt.sm,
			'q-pa-lg': $q.screen.lt.sm
		}">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Your contact data
			</h1>
			<div>
				Emergency messages are sent by an automated messaging system and you can add, amend, or delete your personal
				data at any time. If you move address, or decide that you no longer wish to receive emergency messages about
				{{ site_name }}, you can
					<router-link :to="{name: 'deregister'}" class="text-links-color" @click.native="redirect">
						remove your contact information
					</router-link>
					from {{ service_name }} at any time via this website.
			</div>
			<div class="q-mt-md">
				By registering your contact information, you are consenting to Alert Cascade Limited (Alert Cascade)
				processing your data for the purposes of sending emergency messages on behalf of {{customer_name}} in
				relation to {{ site_name }}. Your data will only be used for this purpose, will not be shared with anyone
				else (unless required by law), and will not be used for marketing. Alert Cascade is registered with the
				ICO under
				<a target="_blank" href="http://www.ico.org.uk" class="text-links-color">registration number ZA189919</a>.
			</div>
		</div>

		<div :class="{
			'q-pl-xl': $q.screen.gt.sm,
			'q-pr-xl': $q.screen.gt.sm,
			'q-pt-md': $q.screen.gt.sm,
			'q-pb-lg': $q.screen.gt.sm,
			'q-pa-lg': $q.screen.lt.sm
		}">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Need help?
			</h1>
			<div>
				If you need help to register your contact information, or support with any part of this website, please get in
				touch with our support team:
			</div>
			<div class="row q-gutter-sm q-mt-sm">
				<div>
					<i class="fa fa-envelope text-links-color"></i>
					<span><a class="pointer remove-text-decoration text-links-color" href="mailto:support@alertcascade.co.uk">
						support@alertcascade.co.uk
					</a></span>
				</div>
				<div>
					<i class="fa fa-phone text-links-color"></i>
					<span><a href='tel:01733785999' class="text-links-color"> 01733 785999</a></span>
				</div>
			</div>
		</div>
		<MultiBranding/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RegisterButton from '@/components/registration_template/RegisterButton.vue'
import MultiBranding from '@/components/registration_template/MultiBranding.vue'

export default {
	name: 'LandingTemplatePRP',
	components: {
		RegisterButton,
		MultiBranding
	},
	computed: {
		...mapGetters([
			'customer_name',
			'service_name',
			'leaflet',
			'leaflet_name',
			'site_name',
			'noticeboard_phone_number'
		]),
	},
	methods: {
		...mapActions(['updateActiveMenuItem']),
		redirect () {
			this.updateActiveMenuItem(this.$route.name)
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
}
</script>

<style scoped lang='scss'>
	.landing {
		font-size: 16px;
	}
	.landing > div:nth-child(even){
		background: white;
	}
	.remove-text-decoration {
		text-decoration: none;
	}

	@media(max-width: 800px) {
		.landing {
			font-size: 13px;
		}
	}

</style>
