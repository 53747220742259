<template>
	<q-btn
		rounded
		color="register-button"
		icon="how_to_reg"
		:label="btn_label"
		:class="$q.screen.lt.sm ? 'q-py-sm' : 'q-py-md'"
		:size='$q.screen.lt.sm ? "sm" : "12px"'
		@click="redirectToRegister"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'RegisterButton',
	computed: {
		...mapGetters([
			'register_button',
			'service_name'
		]),
		btn_label () {
			return `Register`
		}
	},
	methods: {
		...mapActions(['updateActiveMenuItem']),
		redirectToRegister() {
			this.$router.push({
				name: 'register'
			})
			this.updateActiveMenuItem('register')
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},
	},
}
</script>

