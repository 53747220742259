<template>
	<div class="row reg-content q-pa-lg q-col-gutter-x-lg bg-grey-3 items-center">
		<div class="col-md-6">
			<div :class="$q.screen.lt.sm ? '' : 'q-py-lg q-px-xl'">
				<p>
					By de-registering my contact details from the {{ customer_name }} {{ service_name }}, I understand that:
				</p>

				<ul class="bullet-points-color">
					<li>
						My contact data will be securely deleted within 48 hours of my request to de-register,
						so I may still receive messages during that time.
					</li>
					<li>
						After 48 hours, I will no longer receive emergency information or messages
						from the {{ customer_name }} {{ service_name }}.
					</li>
					<li>
						I can re-register for this service at any time in the future.
					</li>
				</ul>
			</div>
		</div>
		<div class="col-md-6 justify-center" style="max-width: 100%;">
			<div :class="$q.screen.lt.sm ? '' : 'q-py-lg q-px-xl'">
				<deregister-form/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeregisterForm from '@/components/registration_template/DeregisterForm.vue'

export default {
	name: 'Deregister',
	meta: {
		title: 'Alert Cascade - De-register'
	},
	components: {
		DeregisterForm,
	},
	computed: {
		...mapGetters([
			'customer_name',
			'service_name'
		])
	},
}
</script>

<style scoped>

	.reg-content {
		font-size: 16px;
		cursor: default;
		line-height: 2em;
	}

	.reg-content li:before {
		content: "■";
		font-size: 18px;
	}

	.reg-content ul li {
		padding-bottom: 10px;
		list-style: none;
		text-indent: -.7em;
	}

	@media(max-width: 800px) {
		.reg-content {
			font-size: 13px;
		}
		.reg-content ul {
			padding: 1rem;
		}
	}
</style>
