<template>
	<div class="landing">
		<div class="q-pl-xl q-pr-xl q-pt-md q-pb-lg bg-grey-3">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Sign up for the {{ customer_name }} {{ service_name }}
			</h1>
			<div class="flex column">
				<div>
					As part of our {{ reason }}, {{ customer_name }} provides stakeholders with the option to register for our
					free alerting service. This service is called {{ service_name }}, and may be used to contact you in extreme
					situations such as security threats, disaster, emergencies, and any unexpected disruption to normal
					working arrangements.
				</div>
				<div class="q-mt-md">
					Registering your contact information (mobile, landline, and email) means we can send you emergency
					information and keep you informed about situations that may affect you.
				</div>
				<div class="q-mt-md self-center">
					<register-button/>
				</div>
			</div>
		</div>

		<div class="q-pl-xl q-pr-xl q-pt-md q-pb-lg">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Your contact data
			</h1>
			<div>
				Our messages are sent by an automated messaging system and you can add, amend, or delete your personal
				information at any time. If you are leaving us, or no longer wish to receive messages from the
				{{ customer_name }} {{ service_name }} you can
					<router-link :to="{name: 'deregister'}" class="text-links-color" @click.native="redirect">
						remove your contact information
					</router-link>
				from {{ service_name }} at any time via this website.
			</div>
			<div class="q-mt-md">
				By registering your contact information, you are consenting to
					<a href="https://alertcascade.co.uk/"
						class="register-template-link pointer remove-text-decoration text-links-color"
						target="_blank"
					>
						Alert Cascade Limited
					</a>
				processing your data for the purposes of sending emergency messages on behalf of {{ customer_name }}.
				Your data will only be used for this purpose, will not be shared with anyone else (unless required by law),
				and will not be used for marketing. Alert Cascade is registered with the ICO under
				<a target="_blank" href="http://www.ico.org.uk" class="text-links-color">registration number ZA189919</a>.
			</div>
		</div>

		<div class="q-pl-xl q-pr-xl q-pt-md q-pb-lg">
			<h1 :class="{'text-h6': !$q.screen.gt.sm, 'text-h5' : $q.screen.gt.sm, 'fields-tab-header': true}">
				Need help?
			</h1>
			<div>
				If you need help to register your contact information, or support with any part of this website, please get in
				touch with our support team:
			</div>
			<div class="row q-gutter-sm q-mt-sm">
				<div>
					<i class="fa fa-envelope text-links-color"></i>
					<span><a class="pointer remove-text-decoration text-links-color" href="mailto:support@alertcascade.co.uk">
						support@alertcascade.co.uk
					</a></span>
				</div>
				<div>
					<i class="fa fa-phone text-links-color"></i>
					<span><a href='tel:01733785999' class="text-links-color"> 01733 785999</a></span>
				</div>
			</div>
		</div>
		<MultiBranding/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RegisterButton from '@/components/registration_template/RegisterButton.vue'
import MultiBranding from '@/components/registration_template/MultiBranding.vue'

export default {
	name: 'LandingTemplateSRP',
	components: {
		RegisterButton,
		MultiBranding
	},
	computed: {
		...mapGetters([
			'customer_name',
			'service_name',
			'reason',
		]),
	},
	methods: {
		...mapActions(['updateActiveMenuItem']),
		redirect () {
			this.updateActiveMenuItem(this.$route.name)
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
}
</script>

<style scoped lang='scss'>
	.landing {
		font-size: 16px;
	}
	.landing > div:nth-child(even){
		background: white;
	}

	@media(max-width: 800px) {
		.landing {
			font-size: 13px;
		}
	}
</style>
