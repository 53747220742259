var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-footer',{staticClass:"bg-grey-8 text-white",attrs:{"elevated":""}},[_c('div',{staticClass:"row register-template-footer justify-center"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{class:{
				'q-py-md': _vm.$q.screen.gt.sm,
				'q-px-xl': _vm.$q.screen.gt.sm,
				'text-center': true
			}},[_c('h3',{staticClass:"size-16-bold uppercase"},[_vm._v("ALERT CASCADE LTD")]),_c('ul',[_c('li',[_c('i',{staticClass:"fas fa-map-marker-alt"}),_c('span',[_vm._v(" Allia Future Business Centre, London Road, Peterborough")])]),_c('li',[_vm._v("PE2 8AN")]),_c('li',[_c('i',{staticClass:"fa fa-phone"}),_c('span',[_vm._v(" 01733 785999")])]),_c('li',[_c('i',{staticClass:"fa fa-envelope"}),_c('span',[_c('a',{staticClass:"footer-link pointer",attrs:{"href":"mailto:support@alertcascade.co.uk"}},[_vm._v(" info@alertcascade.co.uk ")])])])])])]),(!_vm.registration_template_settings.template.enable_multi_branding || _vm.multi_branding_all_names_null)?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{class:{
				'q-py-md': _vm.$q.screen.gt.sm,
				'q-px-xl': _vm.$q.screen.gt.sm,
				'text-center': true
			}},[_c('h3',{staticClass:"size-16-bold uppercase"},[_vm._v(" "+_vm._s(_vm.registration_template_settings.template.customer_name)+" ")]),_c('ul',[_c('li',[_c('i',{staticClass:"fas fa-map-marker-alt"}),_c('span',[_vm._v(" "+_vm._s(_vm.registration_template_settings.template.address_1))])]),_c('li',[_vm._v(_vm._s(_vm.registration_template_settings.template.address_2))]),_c('li',[_vm._v(_vm._s(_vm.registration_template_settings.template.address_3))]),_c('li',[_vm._v(_vm._s(_vm.registration_template_settings.template.address_4))]),_c('li',[_c('i',{staticClass:"fa fa-phone"}),_c('span',[_vm._v(" "+_vm._s(_vm.registration_template_settings.template.phone_number))])]),_c('li',[_c('i',{staticClass:"fa fa-envelope"}),_c('span',[_c('a',{staticClass:"footer-link pointer",attrs:{"href":`mailto:${_vm.registration_template_settings.template.email}`}},[_vm._v(" "+_vm._s(_vm.registration_template_settings.template.email)+" ")])])])])])]):_vm._e(),(_vm.registration_template_settings.template.enable_multi_branding && !_vm.multi_branding_all_names_null)?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{class:{
				'q-py-md': _vm.$q.screen.gt.sm,
				'q-px-xl': _vm.$q.screen.gt.sm,
				'text-center': true
			}},[_c('h3',{staticClass:"size-16-bold uppercase"},[_vm._v("Operators")]),_vm._l((_vm.multi_branding),function(multi_brand){return _c('ul',{key:multi_brand.name},[(multi_brand.name)?_c('li',[_c('i',{staticClass:"fa fa-angle-right"}),(multi_brand.link)?_c('a',{staticClass:"footer-link pointer",attrs:{"target":"_blank","href":multi_brand.link ? multi_brand.link : '#'}},[_vm._v(" "+_vm._s(multi_brand.name)+" ")]):_vm._e()]):_vm._e()])})],2)]):_vm._e(),_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{class:{
				'q-py-md': _vm.$q.screen.gt.sm,
				'q-px-xl': _vm.$q.screen.gt.sm,
				'text-center': true
			}},[_c('h3',{staticClass:"size-16-bold uppercase"},[_vm._v("Pages")]),_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-angle-right"}),_c('router-link',{staticClass:"footer-link pointer",attrs:{"to":{ name: 'landing'}},nativeOn:{"click":function($event){return _vm.redirect.apply(null, arguments)}}},[_vm._v(" Welcome ")])],1),_c('li',[_c('i',{staticClass:"fa fa-angle-right"}),_c('router-link',{staticClass:"footer-link pointer",attrs:{"to":{ name: 'register'}},nativeOn:{"click":function($event){return _vm.redirect.apply(null, arguments)}}},[_vm._v(" Register ")])],1),_c('li',[_c('i',{staticClass:"fa fa-angle-right"}),_c('router-link',{staticClass:"footer-link pointer",attrs:{"to":{ name: 'deregister'}},nativeOn:{"click":function($event){return _vm.redirect.apply(null, arguments)}}},[_vm._v(" De-register ")])],1)])])]),_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{class:{
				'q-py-md': _vm.$q.screen.gt.sm,
				'q-px-xl': _vm.$q.screen.gt.sm,
				'text-center': true
			}},[_c('h3',{staticClass:"size-16-bold uppercase"},[_vm._v("Legal")]),_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-angle-right"}),_c('a',{staticClass:"footer-link pointer",attrs:{"href":_vm.registration_template_settings.template.ico_link,"target":"_blank"}},[_vm._v(" Information Commissioner’s Office ")])]),_c('li',[_c('i',{staticClass:"fa fa-angle-right"}),_c('a',{staticClass:"footer-link pointer",attrs:{"href":"https://alertcascade.co.uk/privacy","target":"_blank"}},[_vm._v(" Privacy ")])])])])])]),_c('div',{staticClass:"register-template-copyright-info row q-pa-md"},[_c('div',{staticClass:"company-name offset-md-5 col-md-7"},[_vm._v(" © Alert Cascade Ltd "+_vm._s(_vm.current_year)+" | "),_c('a',{staticStyle:{"color":"inherit"},attrs:{"href":"https://alertcascade.co.uk/","target":"_blank"}},[_vm._v("www.alertcascade.co.uk")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }