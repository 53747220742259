<template>
	<q-card class="bg-grey-1">
		<form class="q-pa-md">
			<div class="q-pa-sm">
				<q-input
					v-model="deregister_input_email"
					label="Email"
					outlined
					:error="has_error['email']"
					:error-message="error_message['email']"
				/>
			</div>
			<div class="q-pa-sm">
				<q-field
					stack-label
					borderless
					label="Phone Number"
					indicator-color="primary"
					active-color="primary"
					:error="has_error['phone_number']"
					:error-message="error_message['phone_number']"
				>
					<vue-tel-input
						v-model="phone_number"
						v-bind="bindProps"
						class="full-width q-pa-sm q-mt-sm"
					>
					</vue-tel-input>
				</q-field>
			</div>
			<div class="q-pa-sm">
				<vue-recaptcha
					v-if="recaptcha"
					:sitekey="recaptcha"
					:size="$q.screen.lt.md ? 'compact' : 'normal'"
					@verify="verifyCaptcha"
				/>
			</div>
			<div class="q-pa-sm">
				<q-btn
					label="De-register"
					color="deregister-button"
					class="full-width"
					@click.prevent="deregister($event)"
					:disable='disable_button'
				/>
			</div>
		</form>
	</q-card>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import RegistrationTemplateAPI from '@/services/api/RegistrationTemplate.js';
import { mapGetters } from 'vuex';

export default {
	name: 'DeregisterForm',
	components: {
		VueRecaptcha
	},
	data () {
		return {
			deregister_input_email: '',
			recaptcha_verified: false,
			recaptcha: "",
			disable_button: true,
			phone_number: null,
			bindProps: {
				mode: "international",
				defaultCountry: "GB",
				placeholder: "Enter a phone number",
			},
			has_error: {},
			error_message: {}
		}
	},
	computed: {
		...mapGetters(['registration_template_settings'])
	},
	methods: {
		async deregister () {
			this.has_error = {}
			this.error_message = {}
			const deregister_data = {
				'email': this.deregister_input_email,
				'phone_number': this.phone_number,
				'customer_group_id': this.registration_template_settings.template.customer_group.id
			}
			const response = await RegistrationTemplateAPI.deleteSelfRegisteredUser(deregister_data);
			if (!response.error) {
				this.$q.notify('User successfully deleted.')
			} else {
				if (response.data == 'Invalid email provided') {
					this.has_error['email'] = true
					this.error_message['email'] = response.data
				} else if (response.data == 'Invalid phone number provided') {
					this.has_error['phone_number'] = true
					this.error_message['phone_number'] = response.data
				}
				else {
					this.$q.notify({
						timeout: 6700,
						message: response.data += ' If you require assistance, please contact support@alertcascade.co.uk.',
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
				}
			}
			window.grecaptcha.reset();
			this.disable_button=true
		},
		verifyCaptcha() {
			this.recaptcha_verified = true;
			this.disable_button=false
		},
	},
	async created() {
		this.recaptcha = await RegistrationTemplateAPI.getRecaptchaKey();
		if (this.recaptcha.error) {
			this.recaptcha = "";
		}
	}
}
</script>

<style>
.vue-tel-input input {
	background-color: #fafafa;
}

.vue-tel-input:focus-within{
	border-color: var(--primary-color);
	border-width: 2px;
	box-shadow: inset 0 0 0
}
</style>
