<template>
	<q-card class="bg-grey-1">
		<form class="q-pa-md">
			<div class="q-pa-sm">
				<q-input
					outlined
					v-model="new_input_email"
					:label="work_email_label"
					:error="has_invalid_field_error['email']"
					:error-message="invalid_field_error_message['email']"
				/>
			</div>

			<div class="q-pa-sm" v-if="has_expiry_date">
				<q-input
					outlined
					v-model="expiry_date"
					label="Expiry date"
					hint="Format DD/MM/YYYY"
					@click="date_dialog=true"
				>
					<template v-slot:append>
						<q-btn flat	icon="calendar_today" class="cursor-pointer date-btn" @click="date_dialog=true"/>
					</template>
				</q-input>
				<q-dialog v-model="date_dialog">
					<q-date
						v-model="expiry_date"
						mask="YYYY-MM-DD"
						:options="limitExpiryDate"
					>
					</q-date>
				</q-dialog>
			</div>

			<register-form-field
				v-for="(field, index) in input_fields"
				:key="field.id"
				:field="field"
				:index="index"
				:has_required_field_error="has_required_field_error"
				:has_invalid_field_error="has_invalid_field_error"
				:invalid_field_error_message="invalid_field_error_message"
				:successfull_registration="successfull_registration"
				@update-field-value="updateFieldValue($event, index)"
			/>

			<label class="text-weight-bold">
				<q-checkbox v-model="agree_to_terms" label="I confirm I agree to the terms outlined above"/>
			</label>
			<div class="q-pa-sm">
				<vue-recaptcha
					v-if="recaptcha"
					:sitekey="recaptcha"
					:size="$q.screen.lt.md ? 'compact' : 'normal'"
					@verify="verifyCaptcha"
				/>
			</div>
			<div class="q-pa-sm">
				<q-btn
					label="Register my details"
					color="register-button"
					class="full-width"
					@click.prevent="register($event)"
					:disable='disable_button'
				/>
			</div>
		</form>
	</q-card>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import RegistrationTemplateAPI from '@/services/api/RegistrationTemplate.js';
import public_registration_utils from '@/utils/public_registration.js';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone'
import RegisterFormField from '@/components/registration_template/RegisterFormField.vue'
import { date } from 'quasar'

export default {
	name: 'RegisterForm',
	components: {
		VueRecaptcha,
		RegisterFormField
	},
	data () {
		return {
			new_input_email: '',
			agree_to_terms: false,
			recaptcha_verified: false,
			recaptcha: "",
			has_expiry_date: false,
			expiry_date: null,
			target_cg_id: '',
			date_dialog: false,
			date_now: null,
			disable_button: true,
			has_required_field_error: false,
			has_invalid_field_error: {},
			invalid_field_error_message: {},
			successfull_registration: false
		}
	},
	computed: {
		...mapGetters([
			'registration_template_settings',
			'input_fields'
		]),
		work_email_label() {
			let label = 'Email'
			if (!this.input_fields.filter(field => field.required).length) {
				return label += ' *'
			}
			return label
		}
	},
	methods: {
		...mapActions([
			'updateInputFields',
			'getPublicRegistrationFields'
		]),
		updateFieldValue(val, index) {
			this.updateInputFields({val, index})
		},
		limitExpiryDate(date){
			return date >= this.date_today
		},
		async register () {
			this.has_required_field_error = false
			this.has_invalid_field_error = {}
			this.invalid_field_error_message = {}
			if (this.recaptcha_verified) {
				if (!this.agree_to_terms) {
					this.$q.notify({
							timeout: 0,
							message: 'You must agree to the terms above.',
							actions: [
								{
									label: 'Dismiss',
									color: 'primary',
								},
							],
						})
					return;
				}

				let new_user_data = public_registration_utils.generatePostData(
					'new_input',
					this.new_input_email,
					this.input_fields
				);

				if (this.has_expiry_date) {
					if (this.expiry_date) {
						new_user_data['time_based_user_expiry_date'] = this.expiry_date;
					}else {
						new_user_data['time_based_user_expiry_date'] = moment().add(1, 'y').format('YYYY-MM-DD');
					}
				}
				const response = await RegistrationTemplateAPI.createSelfRegisteredUser(new_user_data);
				if (response.error) {
					this.handleResponseError(response)
				}else {
					this.$q.notify('Registration successful.')
					this.successfull_registration = true
				}
				window.grecaptcha.reset();
				this.disable_button=true
			}else {
				console.error("Error with recaptcha");
			}
		},
		verifyCaptcha() {
			this.recaptcha_verified = true;
			this.disable_button=false
		},
		handleResponseError(response) {
			if (response.error_message.field) {
				this.handleFieldError(response.error_message.field, response.error_message.error_message)
		} else if (response.error_message.includes('Required')) {
			this.has_required_field_error = true
		} else if (response.error_message.includes('Invalid email')) {
				this.handleFieldError('email', response.error_message)
		} else {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message += ' If you require assistance, please contact support@alertcascade.co.uk.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		handleFieldError(field, error_message) {
			let copy_has_invalid_field_error = JSON.parse(JSON.stringify(this.has_invalid_field_error))
			copy_has_invalid_field_error[field] = true
			this.has_invalid_field_error = copy_has_invalid_field_error
			let copy_invalid_error_message = JSON.parse(JSON.stringify(this.invalid_field_error_message))
			copy_invalid_error_message[field] = error_message
			this.invalid_field_error_message = copy_invalid_error_message
		}
	},
	async created () {
		await this.getPublicRegistrationFields(this.registration_template_settings.instance.id);

		const registration_template = this.registration_template_settings.template;
		const rti = this.registration_template_settings.instance;

		this.recaptcha = await RegistrationTemplateAPI.getRecaptchaKey();
		if (this.recaptcha.error) {
			this.recaptcha = "";
		}

		if (registration_template) {
			this.target_cg_id = registration_template.customer_group_id;
			this.has_expiry_date = rti.has_expiry_date;
		}

		this.date_today = date.formatDate(Date.now(), 'YYYY/MM/DD')
	},
}
</script>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>