<template>
	<div class="reg-content row q-pa-lg q-col-gutter-x-lg bg-grey-3 items-center">
		<div class="col-md-6">
			<div :class="$q.screen.lt.sm ? '' : 'q-py-lg q-px-xl'">
				<p>
					By registering my contact details to receive messages from the {{ customer_name }}'s {{ service_name }},
					I understand that the following terms and conditions will apply:
				</p>
				<ul class="bullet-points-color">
					<li>
						In the unlikely event of an incident, you may receive emergency information in the following ways:
						<ul>
							<li>Voice call to my mobile phone</li>
							<li>SMS text message to my mobile phone</li>
							<li>Email to my email address</li>
						</ul>
					</li>
					<li>
						{{ customer_name }} and Alert Cascade Limited cannot be held accountable for any failure in the telephone or
						cellular networks.
					</li>
					<li>
						If you do not receive any of the messages from the {{ customer_name }}'s {{ service_name }}, {{ customer_name }}
						cannot be held accountable or liable.
					</li>
					<li>
						Only messages authorised by {{ customer_name }} will be sent from the {{ service_name }}.
					</li>
					<li>
						The {{ service_name }} is a messaging system for emergencies at
						{{ site_name }}, and if you need to contact the emergency services you must dial 999 as usual.
					</li>
					<li>
						Messages from the {{ service_name }} may contain emergency instructions and it is
						important that you listen to, or read the whole message.
					</li>
					<li>
						{{ customer_name }} and Alert Cascade Limited will not charge you for this service, but your phone provider
						may charge you to receive voicemails depending on your service plan with them.
					</li>
					<li>
						The personal data that you provide during this registration process will be processed by Alert Cascade Limited
						on behalf of {{ customer_name }} for the purposes of sending emergency information to you, and for testing the
						{{ service_name }} twice a year in accordance with the requirements of current legislation.
					</li>
					<li>
						These terms and conditions may change. If they do change, you will be told about the changes.
					</li>
					<li>
						You can choose to de-register my details at any time.
					</li>
				</ul>
			</div>
		</div>
		<div class="col-md-6 self-center" style="max-width: 100%;">
			<div :class="$q.screen.lt.sm ? '' : 'q-py-lg q-px-xl'">
				<register-form/>
			</div>
		</div>
	</div>
</template>

<script>
import RegisterForm from '@/components/registration_template/RegisterForm.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'RegisterTemplatePRP',
	meta: {
		title: 'Alert Cascade - Register'
	},
	components: {
		RegisterForm,
	},
	computed: {
		...mapGetters([
			'customer_name',
			'service_name',
			'site_name'
		])
	},
}
</script>

<style scoped>

	.reg-content {
		font-size: 16px;
		cursor: default;
	}

	.reg-content li:before {
		content: "■";
		font-size: 18px;
		margin-right: 5px;
	}

	.reg-content ul li {
		padding-bottom: 10px;
		list-style: none;
		text-indent: -.7em;
	}

	@media(max-width: 800px) {
		.reg-content {
			font-size: 13px;
		}
		.reg-content ul {
			padding: 1rem;
		}
	}
</style>
