
<template>
	<div v-if="multi_branding.length" class="flex justify-center items-center q-pa-md">
		<div v-for="multi_brand in multi_branding" :key="multi_brand.name">
			<div class="q-ma-sm" style="height: 50px;">
				<img
					v-if="
						registration_template_settings.template.enable_multi_branding &&
						multi_brand.logo &&
						!multi_brand.logo.endsWith('False')
						"
					style="height: inherit; width: auto;"
					:src="multi_brand.logo"
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'MultiBranding',
	computed: {
		...mapGetters(['registration_template_settings', 'multi_branding', 'noticeboard_phone_number'])
	}
}
</script>