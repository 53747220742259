var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing"},[_c('div',{class:{
		'q-pl-xl': _vm.$q.screen.gt.sm,
		'q-pr-xl': _vm.$q.screen.gt.sm,
		'q-pt-md': _vm.$q.screen.gt.sm,
		'q-pb-lg': _vm.$q.screen.gt.sm,
		'q-pa-lg': _vm.$q.screen.lt.sm
	}},[_c('h1',{class:{'text-h6': !_vm.$q.screen.gt.sm, 'text-h5' : _vm.$q.screen.gt.sm, 'fields-tab-header': true}},[_vm._v(" Sign up for emergency alerts ")]),_c('div',{staticClass:"flex justify-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.customer_name)+" encourages people living and working near "+_vm._s(_vm.site_name)+" to sign up for our free alerting service, "+_vm._s(_vm.service_name)+". Registering your contact information (mobile, landline, and email) means that we can send you emergency information and safety instructions in the unlikely event of an emergency at "+_vm._s(_vm.site_name)+". ")]),_c('div',{staticClass:"q-mt-md"},[_c('register-button')],1)])]),(_vm.leaflet)?_c('div',{class:{
		'q-pl-xl': _vm.$q.screen.gt.sm,
		'q-pr-xl': _vm.$q.screen.gt.sm,
		'q-pt-md': _vm.$q.screen.gt.sm,
		'q-pb-lg': _vm.$q.screen.gt.sm,
		'q-pa-lg': _vm.$q.screen.lt.sm
	}},[_c('h1',{class:{'text-h6': !_vm.$q.screen.gt.sm, 'text-h5' : _vm.$q.screen.gt.sm, 'fields-tab-header': true}},[_vm._v(" What to do in an emergency? ")]),_c('div',[_vm._v(" You should already have a copy of our "+_vm._s(_vm.leaflet_name)+", giving you more information about what happens at "+_vm._s(_vm.site_name)+" and explaining what you need to do in an emergency. If you do not have an up-to-date copy of our "+_vm._s(_vm.leaflet_name)+" you can "),_c('a',{staticClass:"text-links-color",attrs:{"target":"_blank","href":_vm.leaflet}},[_vm._v(" download the latest version here ")]),_vm._v(". ")])]):_vm._e(),(_vm.noticeboard_phone_number)?_c('div',{class:{
		'q-pl-xl': _vm.$q.screen.gt.sm,
		'q-pr-xl': _vm.$q.screen.gt.sm,
		'q-pt-md': _vm.$q.screen.gt.sm,
		'q-pb-lg': _vm.$q.screen.gt.sm,
		'q-pa-lg': _vm.$q.screen.lt.sm
	}},[_c('h1',{class:{'text-h6': !_vm.$q.screen.gt.sm, 'text-h5' : _vm.$q.screen.gt.sm, 'fields-tab-header': true}},[_vm._v(" Resident information line ("+_vm._s(_vm.noticeboard_phone_number)+") ")]),_c('div',[_vm._v(" You can call our resident information line 24/7/365 to hear the latest situation at "+_vm._s(_vm.site_name)+". You will usually hear a message stating “There are no current incidents and "+_vm._s(_vm.site_name)+" is operating normally”. If we are conducting an exercise, or in the unlikely event of an emergency, this line will be updated to provide further information. ")])]):_vm._e(),_c('div',{class:{
		'q-pl-xl': _vm.$q.screen.gt.sm,
		'q-pr-xl': _vm.$q.screen.gt.sm,
		'q-pt-md': _vm.$q.screen.gt.sm,
		'q-pb-lg': _vm.$q.screen.gt.sm,
		'q-pa-lg': _vm.$q.screen.lt.sm
	}},[_c('h1',{class:{'text-h6': !_vm.$q.screen.gt.sm, 'text-h5' : _vm.$q.screen.gt.sm, 'fields-tab-header': true}},[_vm._v(" Your contact data ")]),_c('div',[_vm._v(" Emergency messages are sent by an automated messaging system and you can add, amend, or delete your personal data at any time. If you move address, or decide that you no longer wish to receive emergency messages about "+_vm._s(_vm.site_name)+", you can "),_c('router-link',{staticClass:"text-links-color",attrs:{"to":{name: 'deregister'}},nativeOn:{"click":function($event){return _vm.redirect.apply(null, arguments)}}},[_vm._v(" remove your contact information ")]),_vm._v(" from "+_vm._s(_vm.service_name)+" at any time via this website. ")],1),_c('div',{staticClass:"q-mt-md"},[_vm._v(" By registering your contact information, you are consenting to Alert Cascade Limited (Alert Cascade) processing your data for the purposes of sending emergency messages on behalf of "+_vm._s(_vm.customer_name)+" in relation to "+_vm._s(_vm.site_name)+". Your data will only be used for this purpose, will not be shared with anyone else (unless required by law), and will not be used for marketing. Alert Cascade is registered with the ICO under "),_c('a',{staticClass:"text-links-color",attrs:{"target":"_blank","href":"http://www.ico.org.uk"}},[_vm._v("registration number ZA189919")]),_vm._v(". ")])]),_c('div',{class:{
		'q-pl-xl': _vm.$q.screen.gt.sm,
		'q-pr-xl': _vm.$q.screen.gt.sm,
		'q-pt-md': _vm.$q.screen.gt.sm,
		'q-pb-lg': _vm.$q.screen.gt.sm,
		'q-pa-lg': _vm.$q.screen.lt.sm
	}},[_c('h1',{class:{'text-h6': !_vm.$q.screen.gt.sm, 'text-h5' : _vm.$q.screen.gt.sm, 'fields-tab-header': true}},[_vm._v(" Need help? ")]),_c('div',[_vm._v(" If you need help to register your contact information, or support with any part of this website, please get in touch with our support team: ")]),_vm._m(0)]),_c('MultiBranding')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row q-gutter-sm q-mt-sm"},[_c('div',[_c('i',{staticClass:"fa fa-envelope text-links-color"}),_c('span',[_c('a',{staticClass:"pointer remove-text-decoration text-links-color",attrs:{"href":"mailto:support@alertcascade.co.uk"}},[_vm._v(" support@alertcascade.co.uk ")])])]),_c('div',[_c('i',{staticClass:"fa fa-phone text-links-color"}),_c('span',[_c('a',{staticClass:"text-links-color",attrs:{"href":"tel:01733785999"}},[_vm._v(" 01733 785999")])])])])
}]

export { render, staticRenderFns }