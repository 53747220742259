<template>
	<div v-if="field.is_hidden" class="q-pa-sm">
		<!-- NON-PHONE FIELD -->
		<q-input
			v-if="
				!['SINGLESELECT', 'MULTISELECT', 'PHONE(SMS&VOICE)', 'PHONE(SMS)', 'PHONE(VOICE)', 'NUMERIC']
				.includes(field.field_type)
			"
			v-model="selected_option"
			:label="field_label"
			outlined
			:error="has_error"
			:error-message="error_message"
		/>
		<q-input
			v-if="field.field_type === 'NUMERIC'"
			v-model="selected_option"
			:label="field_label"
			outlined
			type="number"
			:error="has_error"
			:error-message="error_message"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
		<q-field
			v-if="field.field_type.includes('PHONE')"
			stack-label
			borderless
			:label="field_label"
			indicator-color="primary"
			active-color="primary"
			:error="has_error"
			:error-message="error_message"
		>
			<vue-tel-input
				v-model="selected_option"
				v-bind="bindProps"
				class="full-width q-pa-sm q-mt-sm"
			>
			</vue-tel-input>
		</q-field>

		<!-- SINGLESELECT -->
		<div v-if="field.field_type == 'SINGLESELECT' && !field.is_map_field">
			<q-select
				v-model="selected_option"
				:options="field_options"
				:label="field_label"
				outlined
				:error="has_error"
				:error-message="error_message"
			/>
		</div>
		<!-- SINGLESELECT MAP -->
		<div v-if="field.field_type == 'SINGLESELECT' && field.is_map_field" class="row q-col-gutter-x-md">
			<q-select
				v-model="selected_option"
				:options="field_options"
				:label="field_label"
				outlined
				class="col-11"
				:error="has_error"
				:error-message="error_message"
			/>
			<div class="col-1 self-center">
				<q-btn round color="secondary" icon="map" @click="showMap" size='xs'>
					<q-tooltip v-model="showing">Show map</q-tooltip>
				</q-btn>
			</div>
		</div>
		<q-dialog
			v-model="show_map"
		>
			<q-card style="width: 700px; max-width: 80vw;">
				<q-card-section>
					<div class="text-h6">Select an area of the map</div>
				</q-card-section>

				<q-card-section class="q-pt-none">
					<div v-if="has_map && !!zones_field">
						<div id="map_container" class="map" style="max-width: 100%;">
							<center>Loading map...If the map fails to load within a few seconds, please refresh the page.</center>
						</div>
					</div>
				</q-card-section>

				<q-card-actions align="right" class="bg-white text-teal">
					<q-btn flat label="Close" v-close-popup />
				</q-card-actions>
			</q-card>
		</q-dialog>

		<!-- MULTISELECT -->
		<div v-if="field.field_type == 'MULTISELECT'">
			<q-select
				v-model="selected_option"
				:options="field_options"
				:label="field_label"
				multiple
				outlined
				:error="has_error"
				:error-message="error_message"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'RegisterFormField',
	props: {
		field: Object,
		has_required_field_error: Boolean,
		required_field_error_message: String,
		invalid_field_error_message: Object,
		has_invalid_field_error: Object,
		successfull_registration: Boolean
	},
	data() {
		return {
			selected_option: null,
			bindProps: {
				mode: "international",
				defaultCountry: "GB",
				placeholder: "Enter a phone number"
			},
			MAP_COLOR: '#FF0000',
			shape_params: {
				strokeWeight: 1,
				strokeOpacity: 0.5,
				strokeColor: this.MAP_COLOR,
				fillColor: this.MAP_COLOR,
				fillOpacity: 0.35,
			},
			selected_zones: null,
			show_map: false,
			showing: false,
			has_error: false
		}
	},
	computed: {
		...mapGetters([
			'has_map',
			'zones_field',
			'sectors_json',
			'map_api_key',,
			'input_fields'
		]),
		field_label() {
			return this.field.required ? `${this.field.label} *` : this.field.label
		},
		error_message() {
			this.has_error=false
			if (this.field.required && this.has_required_field_error && !this.selected_option) {
				this.has_error = true
				return 'This field is required'
			} else if (this.has_invalid_field_error[this.field.label]) {
				this.has_error = true
				return this.invalid_field_error_message[this.field.label]
			}
			return ''
		},
		field_options() {
			if (this.field.options) {
				return this.field.options.map((option) => {
					return {
						label: option.value,
						id: option.id,
						field: this.field.field_id
					}
				})
			}
			return []
		},
	},
	methods: {
		loadScript () {
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `https://maps.googleapis.com/maps/api/js?key=${this.map_api_key}&libraries=geometry`;
			document.body.appendChild(script);
		},

		initMap () {
			const map_container = document.getElementById('map_container');

			const center_coordinates = [-3.4979565, 54.42034389];
			const center = { lng: center_coordinates[0], lat: center_coordinates[1] };

			// Fine-tune if needed
			const map_options = {
				center: { lng: center_coordinates[0] - 0.01, lat: center_coordinates[1] - 0.015 },
				mapTypeId: google.maps.MapTypeId.TERRAIN,
				zoom: 12,
			};

			const map = new google.maps.Map(map_container, map_options);

			this.drawCentralPoint(map, center);
			this.drawIEPZRegion(map, center);
			this.drawSectors(map);
		},

		drawCentralPoint (map, center) {
			new google.maps.Circle({
				map,
				center,
				radius: 10,
				...this.shape_params,
			});
		},

		drawIEPZRegion (map, center) {
			new google.maps.Circle({
				map,
				center,
				radius: 2000,
				...this.shape_params,
			});
		},

		drawSectors (map) {
			const sectors = JSON.parse(this.sectors_json);
			for (let sector of sectors) {
				this.drawSector(map, sector);
			}
		},

		drawSector (map, { sector_id, path }) {
			let polygon = new google.maps.Polygon({
				path,
				...this.shape_params,
				zIndex: 10,
			});
			polygon.setMap(map);
			polygon.addListener('click', () => this.selectZone(sector_id));
		},

		selectZone (zone1) {
			this.selected_zones = [zone1];
			this.zones_field.options.forEach(it => (it.selected = this.selected_zones.includes(it.value)));

			// Prepare json data
			this.selected_option = this.zones_field.options
				.filter(it => it.selected)
				.map(({ value, id }) => ({
					id,
					label: value,
					field: this.zones_field.field_id,
				}))[0];
			this.$emit('update-field-value', this.selected_option)
			this.$q.notify(`${this.selected_option.label} is selected.`)
		},
		showMap() {
			this.show_map=true
			self = this;
				setTimeout(function () {
						self.initMap()
					}, 1000); //Increase the timeout period if load is slow
			}
	},
	created() {
		if (this.has_map && this.field.is_map_field) {
			this.loadScript();
		}
	},
	watch: {
		'selected_option': function (){
			this.$emit('update-field-value', this.selected_option)
		},
		'successfull_registration': function () {
			this.selected_option = ''
		}
	}
}
</script>

<style>
.vue-tel-input input {
	background-color: #fafafa;
}

.vue-tel-input:focus-within{
	border-color: var(--primary-color);
	border-width: 2px;
	box-shadow: inset 0 0 0
}

.map {
	border: 1px solid black;
	width: 650px;
	height: 750px;
}

</style>
