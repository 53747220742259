import store from '@/store/store.js';

export default {
	generatePostData(data_type, email_value, input_fields) {
		let new_user_data = {
			email: email_value,
			values: [],
			recaptcha: window.grecaptcha.getResponse(),
		};
		// Determine if registration is via qr
		if (!!location.search) {
			const [key, value] = location.search.split('=')
			// Ignore all other potential query params
			if (key === '?qr') {
				new_user_data.is_qr_registration = JSON.parse(value);
			}
		}

		for (let i = 0; i < input_fields.length; i++) {
			if (input_fields[i].field_type == 'MULTISELECT') {
				if (input_fields[i][data_type]) {
					let multiselect_objs = [];
					for (let j = 0; j < input_fields[i][data_type].length; j++) {
						let obj = input_fields[i][data_type][j]
						obj['field'] = input_fields[i].id
						multiselect_objs.push(obj);
					}
					new_user_data.values.push({
						field: input_fields[i].field_id,
						values: multiselect_objs,
					});
				} else {
					new_user_data.values.push({
						field: input_fields[i].field_id,
						values: input_fields[i][data_type],
					});
				}
			} else if (input_fields[i].field_type == 'SINGLESELECT') {
				let value = '';
				for (let j in input_fields[i]['options']) {
					if (input_fields[i][data_type]) {
						if (input_fields[i][data_type]['id'] == input_fields[i]['options'][j].id) {
							value = input_fields[i]['options'][j].value;
						}
					}
				}
				new_user_data.values.push({
					field: input_fields[i].field_id,
					values: value,
				});
			} else {
				// Field is not select
				new_user_data.values.push({
					field: input_fields[i].field_id,
					values: input_fields[i][data_type],
				});
			}
		}
		return new_user_data;
	},
}
