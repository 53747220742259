<template>
	<div class="content-wrapper" v-if="registration_settings_ready">
		<q-layout class="shadow-2 rounded-borders bg-grey-3" v-if="registration_settings_ready">
			<Header :template_type="template_type" />
			<q-page-container class="bg-grey-3">
				<LandingTemplateSRP
					v-if="$route.name === 'landing' && template_type == 1"
				/>
				<LandingTemplatePRP
					v-if="$route.name === 'landing' && template_type == 2"
				/>
				<RegisterTemplateSRP
					v-if="$route.name === 'register' && template_type == 1"
				/>
				<RegisterTemplatePRP
					v-if="$route.name === 'register' && template_type == 2"
				/>
				<Deregister
					v-if="$route.name === 'deregister'"
				/>
				<MultiBranding
					v-if="['register', 'deregister'].includes($route.name)"
				/>
				<q-page-sticky position="bottom-right" :offset="[16, 16]" style="z-index: 9999;">
					<q-btn id="scroll_to_top" fab icon="keyboard_arrow_up" color="primary" @click="scrollToTop()" class="btn-scroll-to-top"/>
				</q-page-sticky>
			</q-page-container>
			<Footer/>
		</q-layout>
	</div>
</template>

<script>
import Footer from '@/components/registration_template/Footer.vue';
import Header from '@/components/registration_template/Header.vue';
import LandingTemplateSRP from '@/components/registration_template/LandingTemplateSRP.vue';
import LandingTemplatePRP from '@/components/registration_template/LandingTemplatePRP.vue';
import RegisterTemplateSRP from '@/components/registration_template/RegisterTemplateSRP.vue';
import RegisterTemplatePRP from '@/components/registration_template/RegisterTemplatePRP.vue';
import Deregister from '@/components/registration_template/Deregister.vue';
import MultiBranding from '@/components/registration_template/MultiBranding.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'PublicRegistration',
	meta: {
		title: 'Alert Cascade - Public Registration'
	},
	components: {
		Footer,
		Header,
		LandingTemplateSRP,
		LandingTemplatePRP,
		RegisterTemplateSRP,
		RegisterTemplatePRP,
		Deregister,
		MultiBranding
	},
	data() {
		return {
			registration_settings_ready: false,
			template_type: 1,
			drawerLeft: false,
			menu_item: 'landing',
			menuList: [
				{
					icon: 'home',
					label: 'Welcome',
					separator: true,
					value: 'landing'
				},
				{
					icon: 'how_to_reg',
					label: 'Register',
					separator: true,
					value: 'register'
				},
				{
					icon: 'person_remove',
					label: 'De-register',
					separator: true,
					value: 'deregister'
				},
			]
		}
	},
	computed: {
		...mapGetters([
			'registration_template_settings',
		]),
	},
	methods: {
		...mapActions([
			'getRegistrationTemplateSettings',
			'getRegistrationTemplateMultiBranding',
			'getMapCoordinates',
			'getCustomLoginUrl'
		]),
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},
	},
	async created() {
		const host = window.location.hostname;
		const subdomain = host.split('.')[0];

		await this.getRegistrationTemplateSettings(subdomain);
		const cg_id = this.registration_template_settings.template.customer_group_id;

		await this.getRegistrationTemplateMultiBranding(cg_id);
		await this.getCustomLoginUrl(cg_id)
		this.registration_settings_ready = true;
		this.template_type = this.registration_template_settings.template.template_type;
		await this.getMapCoordinates(cg_id);

		const btnScrollToTop = document.querySelector("#scroll_to_top");
		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 100) {
				btnScrollToTop.classList.add("active");
			} else {
				btnScrollToTop.classList.remove("active");
			}
		});
	},
}
</script>

<style scoped>
.content-wrapper {
	padding: 0;
}
.btn-scroll-to-top {
	opacity: 0;
}
.btn-scroll-to-top.active{
  opacity: 1;
}
</style>
